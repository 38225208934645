<div #beginning class="features">
    <div class="feature hide-element-initially">
        <div class="heading">
            <h2 class="line-decor">{{heading}}</h2>
            <div class="line"></div>
        </div>
        <p>{{body}}</p>
    </div>
    <div class="feature side-image hide-element-initially">
        <img [src]="image" style="width: 100%;" alt="Feature">
    </div>
</div>