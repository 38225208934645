import { CssChangeService } from './../../services/css-change.service';
import { AfterViewInit, ElementRef } from '@angular/core';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';

const TOLERANCE_ZONE = 100;

class Feature {
  processedIcon: SafeHtml;
  constructor(public imageUrl: string, public title: string,
    public icon: string, public cssOrder: number, public body: string) { }
}

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, AfterViewInit {
  private distanceFromTop;

  selected: any;
  firstList: any;
  firstListCurrentIndex = 0;

  secondList: any;
  secondListCurrentIndex = 1;

  @ViewChild('beginning') firstElement: ElementRef;

  constructor(private cssChangeService: CssChangeService, private sanitizer: DomSanitizer,
    private titleService: Title) {
    titleService.setTitle('Bonitas ICT');
    this.firstList = [
      new Feature('assets/img/features1.jpg', 'Web Development', 'desktop', 0, `
          Our web development solution is designed to meet the specific need of each client 
          by incorporating unique features in your web app. Our highly trained experts are 
          always up-to-date with the newest technological models to provide clients with 
          digital tools that solve challenges and adds value to your business. Our web application 
          services ranges from analysis on your business ecosystem, requirements, UI/UX design, 
          QA testing, maintenance and support.
      `),

      new Feature('assets/img/features2.jpg', 'Mobile Development', 'mobile', 3, `
          BICT’s team of mobile app developers delivers a full cycle of application design, 
          integration, and management services. The expertise of our team covers a wide range 
          of devices and operating systems which enables us to give your business an application 
          with rich user interface and seamless experience. We deliver robust apps that are 
          secured and scalable, efficient and usable, well designed and thoughout, as well as
          standard and cross-platform.
      `),
    ];

    this.secondList = [
      new Feature('assets/img/features3.jpg', 'Cloud Computing', 'cloud', 1, `
          Acquiring and maintaining expensive hardware and software resources, and streamlining 
          these resources in the most efficient manner are challenges our cloud computing 
          services handles with numerous benefits that surpasses your expectations. Our 
          services are customized according to the needs of the clients to allow a cost-effective 
          solution. Our team of experts ensures that your cyber security is not compromised while 
          you access your files from anywhere at any time from any device. Scale your business with
          us.
      `),

      new Feature('assets/img/features4.jpg', 'Data Management', 'database', 2, `
          We provide efficient and effective data management services for both local and 
          international businesses to enhance the speed in data accessibility. 
          We offer a wide range of business capabilities around data management from data 
          quality analytics, address validation or deduplication to enhance the quality of 
          data access by end-users like your salespeople and customers. Our team of verse 
          experts in data maintenance, security and insights driving will enable your organization 
          transform data into actionable information and utilize them for effective decision making.
      `),
    ];
  }

  mouseEnter(htmlDomElement) {
    htmlDomElement.classList.add('background-color--visible');
  }

  mouseLeave(htmlDomElement) {
    htmlDomElement.classList.remove('background-color--visible');
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    if (window.scrollY >= this.distanceFromTop - TOLERANCE_ZONE) {
      this.cssChangeService.component.isDarkBg = true;
    } else if (window.scrollY < this.distanceFromTop - TOLERANCE_ZONE) {
      this.cssChangeService.component.isDarkBg = false;
    }
  }

  ngOnInit(): void {
    this.firstList.forEach((feature) => {
      feature.processedIcon = this.sanitizer.bypassSecurityTrustHtml(`
          <svg class="svg-icon">
              <use xlink:href="#icon-${feature.icon}"></use>
          </svg>
          <figcaption>${feature.title}</figcaption>
      `);
    });
    this.secondList.forEach((feature) => {
      feature.processedIcon = this.sanitizer.bypassSecurityTrustHtml(`
          <svg class="svg-icon">
              <use xlink:href="#icon-${feature.icon}"></use>
          </svg>
          <figcaption>${feature.title}</figcaption>
      `);
    });

    this.selected = [
      this.firstList[0],
      this.secondList[1]
    ];

    setInterval(() => {
      let active = this.firstListCurrentIndex;
      active = Math.abs(active - 1);
      this.firstListCurrentIndex = active;
      this.selected[0] = this.firstList[active];

      active = this.secondListCurrentIndex;
      active = Math.abs(active - 1);
      this.secondListCurrentIndex = active;
      this.selected[1] = this.secondList[active];
    }, 4000);
  }

  ngAfterViewInit(): void {
    this.distanceFromTop = this.firstElement.nativeElement.getBoundingClientRect().top;
  }

}
