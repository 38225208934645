import { Injectable, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CssChangeService {

  mapping: Map<string, { element: ElementRef, changeFunction: () => void }>;
  subject: Subject<any>;
  component;

  constructor() {
    this.mapping = new Map;
  }

  add(component) {
    this.component = component;
  }

  activate(name: string) {
    this.mapping[name].changeFunction(this.mapping[name].element);
  }

}
