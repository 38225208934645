import Splide from '@splidejs/splide';
import { Component, OnInit, AfterViewInit, Input, ElementRef, ViewChild } from '@angular/core';
import { DrawComponent } from 'src/app/draw/draw.component';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit, AfterViewInit {
  @Input() slideTo;
  @ViewChild("hero") heroSection: ElementRef;
  @ViewChild("heroDrawing") drawingComponent: DrawComponent;
  private isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  scrollToElement(element): void {
    element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  // Only trigerred, should the user resize the browser window
  // See (window:resize)="customResizer($event)" in hero.component.html
  customResizer(event) {
    setTimeout(() => { // Wait for our generic resizer to finish, then do this custom resize
      let [w, h] = [this.heroSection.nativeElement.clientWidth, this.heroSection.nativeElement.clientHeight];
      this.drawingComponent.forceUpdateDimension(w, h);
    }, 2); // Do it quickly, so it can't be noticed
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    /* Related to DrawingComponent */
    // I want the dimension for this particular drawing <drawing #heroDrawing> that is inside <section #heroSection>,
    // to be based on the width and height of a <section class="hero is-fullheight">
    let [w, h] = [this.heroSection.nativeElement.clientWidth,
    this.heroSection.nativeElement.clientHeight];
    this.drawingComponent.forceUpdateDimension(w, h);

    // Out comment when using SSR
    new Splide('.splide', {
      type: 'loop',
      height: '90vh',
      autoplay: true,
      interval: 3000,
      pauseOnHover: false,
      pauseOnFocus: false
    }).mount();
  }

}
