import { ViewChild } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Content } from 'src/utilities/content';

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.scss']
})
export class OurServicesComponent implements OnInit, AfterViewInit {
  ourServices: Content[];
  @ViewChild('beginning') firstElement: ElementRef;
  @ViewChildren('item') animatables: QueryList<ElementRef>;
  // QueryList is read only, so we use this for copying so we can remove done elements
  animatablesCopy: ElementRef[];
  callBackCount: number = 0;
  hasAnimated = false;

  constructor(private sanitizer: DomSanitizer, private deviceDetector: DeviceDetectorService,
    private titleService: Title) {
    titleService.setTitle('Our Services - Bonitas ICT')
    this.ourServices = [
      new Content('Web Development', 'desktop', `
          Our highly trained experts are always up-to-date with the newest technological 
          models to provide clients with digital tools that solve challenges and adds 
          value to your business.
      `),
      new Content('Mobile App Development', 'mobile', `
          We deliver robust apps that are secured and scalable to give you an 
          application that provides your user with a rich interface and seamless 
          experience on any mobile device.
      `),
      new Content('Cloud Computing', 'cloud', `
          Our expertise includes acquiring, maintaining and securing expensive hardware 
          and software resources, and streamlining these resources in the most efficient 
          manner with numerous benefits that surpasses your expectations.
      `),
      new Content('Web Services', 'web-service', `
          We offer a range of services thA web service is any piece of software that makes 
          itself available over the internet and uses a standardized XML messaging system.
      `),
      new Content('Creative Design', 'frame', `
          We deliver striking visuals for your business that not only communicate your message 
          about a service or products in a unique way, but also attracts in a memorable way.
      `),
      new Content('Database Management', 'database', `
          We provide efficient and effective data management services that enhance the 
          speed in data accessibility and accuracy of data acquired to enable effective 
          decision-making.
      `),
    ]
  }

  lift(htmlDomElement) {
    htmlDomElement.classList.add('background-color--visible');
  }

  drop(htmlDomElement) {
    htmlDomElement.classList.remove('background-color--visible');
  }

  callback = (entries, observer) => {
    ++this.callBackCount;

    if (this.deviceDetector.isMobile())
      return; // no animation in mobile view

    entries.forEach(entry => {
      if (!this.hasAnimated && this.callBackCount > 1 && entry.isIntersecting == true) {
        this.hasAnimated = true;
        // animation action!
        this.animatablesCopy.forEach((element, index) => {
          setTimeout(() => {
            element.nativeElement.classList.add('flip-in-hor-bottom');
            element.nativeElement.classList.remove('hide-element-initially');
            // remove, so our hover effect won't spoil
            setTimeout(() => {
              element.nativeElement.classList.remove('flip-in-hor-bottom')
              this.animatablesCopy.splice(index, 1);
            }, 600)
          }, 200 * (index + 1))
        });
      }
    });
  };

  ngOnInit(): void {
    this.ourServices.forEach((service) => {
      service.processedIcon = this.sanitizer.bypassSecurityTrustHtml(`
        <svg class="svg-icon">
            <use xlink:href="#icon-${service.icon}"></use>
        </svg>
      `);
    })
  }

  ngAfterViewInit(): void {
    this.animatablesCopy = this.animatables.toArray();

    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.75
    }
    let observer = new IntersectionObserver(this.callback, options);
    observer.observe(this.firstElement.nativeElement);

    // scroll to where animation will be played, after some time
    setTimeout(() => {
      this.firstElement.nativeElement
        .scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
    }, 1200);

    // Ensure all items are visible after a long period of time (a fail safe)
    this.animatables.forEach(element => {
      setTimeout(() => {
        element.nativeElement.classList.remove('hide-element-initially');
      }, 3000);
    });
  }

}