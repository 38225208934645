<header #hero id="hero" class="hero">
    <!-- We need #hero, so we can fetch its dimensions -->
    <div class="hero-art" (window:resize)="customResizer($event)">
        <drawing #heroDrawing [drawingName]="'HeroArtDrawing'"></drawing>
    </div>

    <div class="center">
        <div class="splide">
            <div class="splide__track">
                <ul class="splide__list">
                    <li class="splide__slide">
                        <div class="inner">
                            <h1 class="title">Welcome To <span class="yellow">Bonitas</span>
                                <span class="blue">ICT</span>
                            </h1>
                            <h3>We Build Awesome Solutions For Your Business</h3>
                            <button class="button is-primary"
                                    (click)="scrollToElement(bottom)">Learn
                                More</button>
                        </div>
                    </li>
                    <li class="splide__slide">
                        <div class="inner">
                            <h1 class="title">We <span class="yellow">Are </span>
                                <span class="blue">Innovation</span>
                            </h1>
                            <h3>We Design Innovative Strategies For You To Succeed</h3>
                            <button class="button is-primary"
                                    (click)="scrollToElement(bottom)">Learn
                                More</button>
                        </div>
                    </li>
                    <li class="splide__slide">
                        <div class="inner">
                            <h1 class="title">We <span class="yellow">Are The </span>
                                <span class="blue">Future</span>
                            </h1>
                            <h3>We Leverage Cutting Edge Technology to Serve You</h3>
                            <button class="button is-primary"
                                    (click)="scrollToElement(bottom)">Learn
                                More</button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div #bottom id="bottom" title="For scroll to"
             style="position: relative; top: 10px; width: 0; height: 0;"></div>
    </div>
</header>
