import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-css-glitch',
  templateUrl: './css-glitch.component.html',
  styleUrls: ['./css-glitch.component.scss']
})
export class CssGlitchComponent implements OnInit {
  @Input() text;
  constructor() { }

  ngOnInit(): void {
  }

}
