import { ViewChild } from '@angular/core';
import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, AfterViewInit {
  @ViewChild('beginning') firstElement: ElementRef;
  @ViewChildren('item') animatables: QueryList<ElementRef>;
  // QueryList is read only, so we use this for copying so we can remove done elements
  animatablesCopy: ElementRef[];
  callBackCount: number = 0;
  hasAnimated = false;

  constructor(private titleService: Title) {
    titleService.setTitle('Contact Us - Bonitas ICT')
  }

  lift(htmlDomElement) {
    htmlDomElement.classList.add('background-color--visible');
  }

  drop(htmlDomElement) {
    htmlDomElement.classList.remove('background-color--visible');
  }

  callback = (entries, observer) => {
    ++this.callBackCount;

    entries.forEach(entry => {
      if (!this.hasAnimated && this.callBackCount > 1 && entry.isIntersecting == true) {
        this.hasAnimated = true;
        // oya come
        this.animatablesCopy.forEach(element => {
          element.nativeElement.classList.add('slit-in-vertical');
          element.nativeElement.classList.remove('hide-element-initially');
        });
      }
    });
  };

  ngAfterViewInit(): void {
    this.animatablesCopy = this.animatables.toArray();

    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 1
    }
    let observer = new IntersectionObserver(this.callback, options);
    observer.observe(this.firstElement.nativeElement);

    // scroll to where animation will be played, after some time
    setTimeout(() => {
      this.firstElement.nativeElement
        .scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
    }, 1200);

    // Ensure all items are visible after a long period of time (a fail safe)
    this.animatables.forEach(element => {
      setTimeout(() => {
        element.nativeElement.classList.remove('hide-element-initially');
      }, 3000);
    });
  }

  ngOnInit(): void {
  }

}
