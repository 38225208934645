<app-top-banner [img]="'assets/img/about.jpg'" [alt]="'About'"
                [text]="'We build awesome solutions!'"></app-top-banner>

<!-- scroll-margin style rule helps scrollIntoView() scroll to a position above the scroll-to target -->
<section #beginning class="section container" style="scroll-margin: 130px;">
    <div class="heading">
        <h2 class="line-decor">About us</h2>
        <div class="line"></div>
    </div>
    <div class="items-grid">
        <article #animatable *ngFor="let content of aboutUs" class="item hide-element-initially"
                 [class.shadow-effect]="content.type == 'content'">
            <div *ngIf="content.type == 'content'" class="item inflate-flex-basis"
                 (mouseenter)="lift(bg)" (mouseleave)="drop(bg)" (touchmove)="lift(bg)"
                 (touchend)="drop(bg)">
                <div class="icon-wrapper">
                    <img src="assets/img/art-right.svg">
                    <img #bg src="assets/img/art-right-second-color.svg" class="background-color">
                    <figure class="front-icon" [innerHTML]="content.processedIcon"></figure>
                </div>
                <h2>{{content.title}}</h2>
                <p style="line-height: 2.2;" [innerHTML]="content.body"></p>
            </div>
            <figure *ngIf="content.type == 'image'" class="about-image">
                <img src="/assets/img/{{content.icon}}" alt="Bonitas Way" srcset="">
            </figure>
        </article>
    </div>
</section>
