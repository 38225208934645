import { SimpleHeroArtDrawing } from './drawings/simple-hero-art';
import { DrawComponent } from './draw.component';
import { Injectable } from '@angular/core';
import { HeroArtDrawing } from './drawings/hero-art';

export interface Drawing {
  setup(base: DrawComponent);
  draw();
}

@Injectable({
  providedIn: 'root'
})
export class InstanceLoader<T> {
  constructor() {
    /* Initialize drawing prototypes, but keep them light and unloaded */
    (window as any).SimpleHeroArtDrawing = SimpleHeroArtDrawing;
    (window as any).HeroArtDrawing = HeroArtDrawing;
  }

  getInstance(className: string): T {
    let instance = new window[className]();
    return <T>instance;
  }
}
