import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-feature-desc',
  templateUrl: './feature-desc.component.html',
  styleUrls: ['./feature-desc.component.scss']
})
export class FeatureDescComponent implements OnInit, AfterViewInit {
  @ViewChild('beginning') firstElement: ElementRef;
  distanceFromTop: number;
  callBackCount: number = 0;
  hasAnimated = false;

  @Input() heading: string;
  @Input() image: string;
  @Input() body: string;

  constructor(private deviceDetector: DeviceDetectorService) { }

  callback = (entries, observer) => {
    ++this.callBackCount;

    if (this.deviceDetector.isMobile())
      return; // no animation in mobile view

    entries.forEach(entry => {
      if (!this.hasAnimated && this.callBackCount > 1 && entry.isIntersecting == true) {
        this.hasAnimated = true;
        Array.from(this.fl.children).forEach((child: any) => {
          child.classList.remove('hide-element-initially');
        });
        this.fl.children.item(0).classList.add('tilt-in-fwd-tl');
        this.fl.children.item(1).classList.add('tilt-in-fwd-tr');
      }
    });
  };

  get fl() {
    return this.firstElement.nativeElement;
  }

  ngAfterViewInit(): void {
    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.70
    }
    let observer = new IntersectionObserver(this.callback, options);
    observer.observe(this.firstElement.nativeElement);

    // Ensure all items are visible after a long period of time (a fail safe)
    Array.from(this.fl.children).forEach((element: any) => {
      setTimeout(() => {
        element.classList.remove('hide-element-initially');
      }, 3000);
    });
  }

  ngOnInit(): void {
  }

}
