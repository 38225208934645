<div class="container first">
    <section class="about">
        <img src="assets/img/bonitas-group.jpeg" style="width: 50px" class="logo" alt="">
        <p>Bonitas ICT is an independent Information and Communication Technology 
            (ICT) consultancy with a reputation built on providing leading-edge, 
            tailored ICT solutions.</p>
    </section>
    <section class="sitemap">
        <div class="column">
            <h3 class="line-decor">Sitemap</h3>
            <div class="line"></div>
            <p><a routerLink="/home">Home</a></p>
            <p><a routerLink="/about">About</a></p>
            <p><a routerLink="/services">Services</a></p>
            <p><a routerLink="/career">Careers</a></p>
            <p><a routerLink="/contact">Contact</a></p>
        </div>
        <div class="column">
            <h3 class="line-decor">Address</h3>
            <div class="line"></div>
            <p>10 Senator Ekwunife Crescent,</p>
            <p>Iyiagu Estate,</p>
            <p>Awka, Anambra State.</p>
        </div>
        <div class="column">
            <h3 class="line-decor">Social</h3>
            <div class="line"></div>

            <ul class="social-media">
                <li>
                    <a href="https://facebook.com/BonitasICT" target="_blank">
                        <svg class="svg-icon">
                            <use xlink:href="#icon-facebook"></use>
                        </svg>
                        <span>Facebook</span>
                    </a>
                </li>
                <li>
                    <a href="https://twitter.com/bonitasict" target="_blank">
                        <svg class="svg-icon">
                            <use xlink:href="#icon-twitter"></use>
                        </svg>
                        <span>Twitter</span>
                    </a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/company/bonitas-ict-solutions"
                       target="_blank">
                        <svg class="svg-icon">
                            <use xlink:href="#icon-linkedin"></use>
                        </svg>
                        <span>LinkedIn</span>
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/bonitasict/" target="_blank">
                        <svg class="svg-icon">
                            <use xlink:href="#icon-instagram"></use>
                        </svg>
                        <span>Instagram</span>
                    </a>
                </li>
            </ul>
        </div>
        <!-- <div class="column">
            <h3 class="line-decor">Newsletter</h3>
            <div class="line"></div>
            <p>Sign up for our mailing list to get the latest news and updates.</p>
            <br>
            <form>
                <div class="field">
                    <div class="control has-icons-right is-mobile-fullwidth">
                        <input type="email" formControlName="email" class="input" placeholder="Email">
                        <svg class="icon is-small is-right svg-icon svg-icon--form">
                            <use xlink:href="#icon-vpn_key"></use>
                        </svg>
                    </div>
                </div>
            </form>
        </div> -->
    </section>
</div>

<section style="background-color: black;">
    <div class="container second">
        <div>Developed by <span class="is-primary">Bonitas ICT</span> (c) 2021. All Rights Reserved
        </div>
    </div>
</section>
