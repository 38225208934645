import { DrawComponent } from './draw/draw.component';
import { NavComponent } from './nav/nav.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './landing/landing.component';
import { FooterComponent } from './footer/footer.component';
import { SvgComponent } from './svg/svg.component';
import { HeroComponent } from './landing/hero/hero.component';
import { AboutComponent } from './about/about.component';
import { CareerComponent } from './career/career.component';
import { FeatureDescComponent } from './landing/feature-desc/feature-desc.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ContactComponent } from './contact/contact.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { CssGlitchComponent } from './top-banner/css-glitch/css-glitch.component';
import { TopBannerComponent } from './top-banner/top-banner.component';
import { ImagesPreloaderComponent } from './images-preloader/images-preloader.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    LandingComponent,
    FooterComponent,
    SvgComponent,
    HeroComponent,
    AboutComponent,
    CareerComponent,
    FeatureDescComponent,
    NotFoundComponent,
    ContactComponent,
    OurServicesComponent,
    CssGlitchComponent,
    TopBannerComponent,
    DrawComponent,
    ImagesPreloaderComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
