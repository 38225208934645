<div class="container">
    <img src="assets/img/logo.png" class="logo" alt="">
    <div #navList class="list-container">
        <ul class="list">
            <li class="list__item" [class.active]="(url == '' || url == '/home') && parent.isDarkBg"
                (click)="gotoHome()">Home</li>
            <li class="list__item" [class.active]="url == '/about'" (click)="gotoAbout()">About</li>
            <li class="list__item" [class.active]="url == '/services'" (click)="gotoServices()">
                Services</li>
            <li class="list__item" [class.active]="url == '/career'" (click)="gotoCareer()">Careers
            </li>
            <li class="list__item" [class.active]="url == '/contact'" (click)="gotoContacts()">
                Contact</li>
        </ul>
    </div>
    <div #burger class="menu-button" (click)="menuClicked($event)">
        <div class="menu-button__slice"></div>
    </div>
</div>
