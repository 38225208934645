import { AppComponent } from './../app.component';
import { ViewportScroller, Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'nav[app-nav]',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, AfterViewInit {
  @ViewChild('burger') private burger: ElementRef;
  @ViewChild('navList') private navList: ElementRef;
  @Input() parent: AppComponent;

  constructor(private router: Router, private me: ElementRef,
    private viewportScroller: ViewportScroller, private location: Location) { }

  menuClicked($event?) {
    this.burger.nativeElement.classList.toggle('menu-button--active');
    this.navList.nativeElement.classList.toggle('list-container--active');
  }

  get url(): string {
    return this.location.path(true);
  }

  public gotoServices(): void {
    this.menuClicked();
    this.parent.isDarkBg = true;
    this.router.navigateByUrl('/services');
  }

  gotoContacts() {
    this.menuClicked();
    this.parent.isDarkBg = true;
    this.router.navigateByUrl('/contact');
  }

  gotoCareer() {
    this.menuClicked();
    this.parent.isDarkBg = true;
    this.router.navigateByUrl('/career');
  }

  gotoAbout() {
    this.menuClicked();
    this.parent.isDarkBg = true;
    this.router.navigateByUrl('/about');
  }

  gotoHome() {
    this.menuClicked();
    if (this.url != '' && this.url != '/home')
      this.parent.isDarkBg = false;
    this.router.navigateByUrl('/home');
  }

  get size() {
    return this.me;
  }

  ngOnInit(): void {
    if (!this.isLandingPage)
      this.parent.isDarkBg = true;
  }

  ngAfterViewInit(): void {
    this.me = this.me.nativeElement.offsetHeight;
  }

  get isLandingPage() {
    return this.parent.isLandingPage;
  }

  ngAfterViewChecked(): void {
  }

}
